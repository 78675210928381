<template>
  <div class="ebp-multiselect">
    <multiselect v-bind="$attrs" v-on="$listeners" />
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  name: "ebp-multiselect",
  components: {
    Multiselect
  }
};
</script>

<style lang="scss">
.ebp-multiselect {
  .multiselect__tags {
    .multiselect__tag {
      background: $primary !important;
      margin-right: 0.3rem !important;
      span {
        color: white;
      }

      .multiselect__tag-icon {
        &:hover {
          background: darken($color: $primary, $amount: 10);
        }
      }
    }
  }

  .multiselect {
    &__content-wrapper {
      max-height: 250px;
    }

    &__content {
      max-height: 250px;
      overflow-y: scroll;
    }
  }
}
</style>
